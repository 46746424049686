.authentication {
  display: flex;
  flex-direction: row;
  background: url('../assets/background-screen.png') no-repeat;
  background-position: bottom right;
  background-size: 800px;
  height: 100vh;

  & > aside {
    max-width: 500px;
    min-width: 400px;
    flex: 1;

    height: 100vh;
    padding: 1.4rem;
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    color: $white;
    background: url('../assets/textured-rectangle.png') no-repeat;

    & > div {
      max-width: 430px;
    }

    h2 {
      font-size: 2rem;
      font-weight: bold;
      margin-bottom: 1rem;
    }

    p {
      font-size: 14px;
      margin-bottom: 3rem;
    }

    footer a {
      padding-top: 1rem;
      color: $white;
      opacity: 0.7;
      margin-right: 20px;
      text-decoration: underline;


      &:last-child {
        margin-right: 0;
      }
    }
  }

  & > main {
    height: 80vh;
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .input-group {
      margin-bottom: 0.7rem;
    }

    button[type="submit"] {
      margin-top: 2rem;
      margin-bottom: 1rem;
    }

    h1 {
      margin-bottom: 1.5rem;
    }

    .form-icon {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin-bottom: 20%;
      opacity: 0.6;

      img {
        width: 60px;
        display: none;
      }
    }
  }
}

@include media-breakpoint-down(md) {
  .authentication aside {
    display: none;
  }

  .authentication main .form-icon img {
    display: block;
  }
}
