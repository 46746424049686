@import '../styles/variables';

$hp-green: #2d8545;

.loading {
  align-items: center;
  background-color: $hp-green;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  margin-left: -$grid-gutter-width / 2;
  margin-right: -$grid-gutter-width / 2;
  padding-left: $grid-gutter-width / 2;
  padding-right: $grid-gutter-width / 2;
  position: fixed;
  right: 0;
  top: 0;
  z-index: $zindex-modal;
}

.loadingAnimation {
  animation: fadeOut 1.85s infinite ease-out;
}

.container {
  position: relative;
}

.image {
  max-width: 300px;
}

.circle {
  animation: scaleIn 1.75s infinite ease-out;
  background-color: $white;
  border-radius: 50%;
  height: 72px;
  left: 2px;
  position: absolute;
  top: -5px;
  width: 72px;
  z-index: $zindex-modal + 1;
}

@keyframes scaleIn {
  from {
    transform: scale(.1, .1);
  }

  to {
    transform: scale(80, 80);
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}
