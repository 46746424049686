.users {
  max-width: 1200px;
  margin: auto;
  padding: 0 16px;
}

table {
  .user-details {
    h5, h6 {
      margin: 0;
    }

    h5 {
      display: flex;
      flex-direction: row;
      align-items: center;

      .badge {
        margin-left: 5px;
      }
    }

    h6 {
      font-weight: normal;
      font-size: 12px;
    }
  }

  .user-activity {
    font-size: 13px;
    white-space: nowrap;
  }
}
