.user-form {
  max-width: 600px;
  margin: auto;
  padding: 0 16px;

  h1 {
    margin-bottom: 24px;
  }

  small {
    color: $gray-600;
    line-height: 1;
    display: block;
    margin-top: 8px;
  }

  .actions {
    width: 100%;
    max-width: 500px;
    margin: auto;
    padding: 1.25rem;
  }

  select.form-control {
    appearance: none;
    background: $gray-100;
    background-image: url("data:image/svg+xml;utf8,<svg fill='gray' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
    background-repeat: no-repeat;
    background-position-x: 99%;
    background-position-y: 55%;
  }
  input.form-control {
    background: $gray-100;

    &::placeholder {
      color: $gray-500;
    }
  }
}

.user-form--state {
  width: 120px;
  margin-left: 4px;
}

.user-form--zip {
  width: 100px;
  margin-left: 4px;
}
.user-form--contact-information {
  .input-group-text {
    width: 72px;
    padding: 0 16px;
  }
}

.user--subscription-management,
.user-form--contact-information,
.user-form--address-information {
  .card-body {
    width: 100%;
    max-width: 500px;
    margin: auto;
  }
  h3 {
    font-size: 1.3rem;
    font-weight: bold;
    margin-bottom: 1rem;

    svg {
      font-size: 1.1rem;
    }

    small {
      font-size: 12px;
      font-weight: normal;
    }
  }
}

.user--subscription-management .new-user {
  button {
    text-align: left;
    width: 50%;
    white-space: normal;
    line-height: 1;
    padding: 16px;

    &:first-child {
      margin-right: 8px;
    }
    &:last-child {
      margin-left: 8px;
    }
  }

  .button-line-1 {
    font-weight: bold;
    font-size: 1.2rem;
    margin-bottom: 3px;
  }

  .button-line-2 {
    font-weight: bold;
    font-size: 1rem;
  }

  .button-line-3 {
    font-weight: normal;
    font-size: 13px;
    margin-top: 12px;
  }
}
