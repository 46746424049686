.user {
  max-width: 800px;
  margin: auto;
  padding: 0 16px;

  &.empty {
    max-width: 500px;
    margin-top: 10%;
  }

  .user--email {
    margin-top: -6px;
    font-size: 1rem;
    a {
      text-decoration: underline;
      font-weight: normal;
      color: $gray-600;
    }
  }

  .user--access-metrics-container {
    overflow: scroll;
  }
  .user--access-metrics {
    min-width: 500px;
    overflow: scroll;
    margin: 16px 0;
    border-top: 1px solid $gray-300;
    border-bottom: 1px solid $gray-300;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    font-size: 12px;
    color: $gray-600;
    padding: 8px 0;

    div {
      white-space: nowrap;
      padding: 0 4px;
    }
  }

  .user--subscription-management {
    small {
      color: $gray-600;
      line-height: 1;
      display: block;
      margin-top: 8px;
    }
  }

  .user--subscription-management,
  .user--account-yearly-metrics,
  .user--contact-details,
  .user--account-metrics {
    font-size: 0.9rem;

    address {
      white-space: nowrap;
    }

    .card-body {
      width: 100%;
      max-width: 600px;
      margin: auto;
    }
    h3 {
      font-size: 1.3rem;
      font-weight: bold;

      svg {
        font-size: 1.1rem;
      }

      small {
        font-size: 12px;
        font-weight: normal;
      }
    }
  }

  .user--account-yearly-metrics .card-body {
    max-width: 100%;
  }
}
