$white:    #ffffff;
$gray-100: #f8f9fa;
$gray-150: #f1f2f5; // Custom color
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6a747f;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black:    #000;

$blue:     #037aff;
$indigo:   #6610f2;
$purple:   #6f42c1;
$pink:     #e83e8c;
$red:      #d63b49;
$orange:   #F4801F;
$yellow:   #ffc107;
$green:    #3f9142;
$teal:     #20c997;
$cyan:     #17a2b8;


$primary:       $green;
$secondary:     $gray-600;
$success:       lighten($primary, 5%);
$info:          $cyan;
$warning:       $yellow;
$danger:        $red;
$light:         $gray-100;
$dark:          $gray-800;
// Custom colors
$help-color:    $blue;
$field-application-color: lighten($blue, 15%);
$field-planting-color: lighten($orange, 15%);


$body-bg: $gray-150;
$body-color: $dark;

$border-radius: 8px;
$border-radius-lg: 12px;
$btn-border-radius: 8px;
$box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

// Request a color level
// Set a specific jump point for requesting color jumps
$theme-color-interval:      8% !default;
@function color-level($color: $primary, $level: 0) {
  $color-base: if($level > 0, $black, $white);
  $level: abs($level);

  @return mix($color-base, $color, $level * $theme-color-interval);
}

$nav-tabs-link-active-bg: #fff;
$nav-pills-link-active-bg: #343a40;
$table-hover-bg: $gray-200;
$btn-secondary-bg: rgba(darken($gray-150, 50%), 0.08);

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
