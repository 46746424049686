@import './variables';
@import '~bootstrap/scss/bootstrap';
@import './header';
@import './users-table';
@import './user-show';
@import './user-form';
@import './authentication';

h1 {
  font-weight: bold;
  font-size: 2rem;
}

section {
  margin-bottom: 100px !important;
}

.dropdown-menu {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  background-clip: border-box;
  border-color: $gray-500;
}

.dropdown-toggle {
  position: relative;

  &::after {
    position: absolute;
    background: $gray-700;
    right: 0;
    bottom: 0;
    clip-path: polygon(5% 0, 50% 90%, 95% 0, 100% 5%, 50% 100%, 0 5%);
    border: 0;
    height: 3px;
    width: 6px;
    margin: 5px;
  }
}

.dropdown-menu.with-arrow {
  margin-top: 8px;

  &:after {
    content: '';
    display: block;
    height: 20px;
    width: 20px;
    background-color: inherit;
    border: inherit;
    position: absolute;
    top: -10px;
    left: calc(50% - 10px);
    clip-path: polygon(0% 0%, 100% 100%, 0% 100%);
    transform: rotate(135deg);
    border-radius: 0 0 0 0.25em;
  }
}

.card.shadow {
  border: 0;
}

.vertical-align-middle {
  vertical-align: middle !important;
}

.table th {
  border-top: 0;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

thead.card-header th {
  color: $dark;
  font-size: 0.8rem;
  text-transform: none;


  &:first-child {
    padding-left: $card-spacer-x;
  }

  &:last-child {
    padding-right: $card-spacer-x;
  }
}

tbody.card-body td {
  &:first-child {
    padding-left: $card-spacer-x;
  }

  &:last-child {
    padding-right: $card-spacer-x;
  }
}

.btn-secondary {
  @include button-variant($btn-secondary-bg, $btn-secondary-bg);
  border: 0;
  white-space: nowrap;

  &:disabled {
    cursor: not-allowed;
  }

  // default/dark
  color: $dark;

  &:hover,
  &:active,
  &:disabled,
  &.active {
    color: inherit !important;
    background-color: rgba($btn-secondary-bg, 0.15) !important;
  }

  // danger
  &.text-danger {
    color: $danger;

    &:hover,
    &:active,
    &:disabled,
    &.active {
      color: darken($danger, 5%) !important;
    }
  }

  // primary
  &.text-primary {
    color: $primary;

    &:hover,
    &:active,
    &:disabled,
    &.active {
      color: darken($primary, 5%);
    }
  }

  &.transparent {
    background: rgba($dark, 0.05);
  }
}

.CircularProgressbar .CircularProgressbar-path {
  stroke: $primary;
}

.CircularProgressbar .CircularProgressbar-trail {
  stroke: $gray-200;
}

.CircularProgressbar .CircularProgressbar-text {
  fill: $dark;
}

.input-group-text {
  background-color: $gray-100;
  color: $gray-600;
  width: 72px;
  padding: 0 16px;
}

.badge {
  padding: 0.2rem 0.35rem;
  font-size: 12px;
  &.badge-primary {
    background: transparent;
    border: 1px solid $primary;
    color: $primary;
    font-weight: normal;
  }
  &.badge-warning {
    background: transparent;
    border: 1px solid $orange;
    color: $orange;
    font-weight: normal;
  }
}

.input-group.inline-group-text {
  .input-group-append {
    .input-group-text {
      background: $input-bg;
      margin-left: -1px;
      border-left: 0;
      z-index: 1;
      width: auto;
      color: $gray-500;
    }
  }

}
