header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: $white;
  border-bottom: 2px solid $gray-300;
  padding: 0.5rem;
  flex-wrap: wrap;
  margin-bottom: 16px;

  & > img {
    margin: 0 12px;
  }

  nav {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0 16px;

    a {
      padding: 4px 0;
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }

  .dropdown button {
    background: $white;
    border: 0;

    &:hover {
      background: $gray-100;
    }

    &:active {
      background: $gray-150 !important;
    }
  }

  .dropdown.show > button {
    background: $white !important;
    border: 0 !important;
  }

  .dropdown-menu.with-arrow::after {
    left: calc(88% - 10px);
  }
}

.header--left,
.header--right,
.header--center {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
}

.header--right {
  justify-content: flex-end;
}

.account-details {
  .account-icon {
    height: 40px;
    width: 40px;
    margin-right: 10px;
    font-size: 1.3rem;
  }

  h5, h6 {
    margin: 0
  }

  h6 {
    font-weight: normal;
    font-size: 12px;
  }
}

.account-icon {
  height: 30px;
  width: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  font-size: 0.9rem;

  &.account-icon--initials {
    background: $primary;
    color: $white;
    font-weight: bold;
  }
}


@include media-breakpoint-down(xs) {

  header nav {
    flex-direction: column;
    order: 3;
    min-width: calc(100% - 16px - 1rem);
    border-top: 1px solid $gray-200;
    padding-top: 8px;
    margin-top: 8px;
  }
  .header--left,
  .header--right {
    flex-direction: column;
    align-items: flex-start;
  }

  .header--right .account {
    order: -1;
  }
}
